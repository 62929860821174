*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.progress-green {
  color: #3aba75;
}

.App {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100000000;
  -webkit-touch-callout: none;
  display: flex;
}

.App__primary__component {
  min-height: 100%;
  position: relative;
}

.App__primary {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100%;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.App__primary,
.App__primary__component {
  display: flex;
  flex-direction: column;
}

.App__primary__component {
  min-height: 100%;
  flex-grow: 1;
  flex-shrink: 0;
}

.App__blur > * {
  filter: blur(3px) grayscale(25%);
}

.AppErrorBoundary > .View {
  border-radius: 0;
}

.AppErrorBoundary > .View > .BlankSlate {
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
}

/* move the .AppFullscreen__cards to the right of the portaled fullscreen component */
#fullscreen-portal {
  display: flex;
  flex-direction: row-reverse;
}

#fullscreen-theme-portal {
  position: fixed;
  inset: 0;
  z-index: 100000000;
}

.App__cards {
  position: fixed;
  inset: 0;
  z-index: 100000000;
}

/* Prevent user interaction with fullscreen elements
and reenable user interaction on their children */
#fullscreen-theme-portal,
.App__cards {
  pointer-events: none;
}

#fullscreen-theme-portal > *,
.App__cards > * {
  pointer-events: auto;
}

/* limit space taken up by cards */
.AppFullscreen__cards {
  display: inline-block;
}

/* Rendering #space-view within as sibling of .App so as to
hide the primary component when #space-view is rendered. */
#space-view ~ .App {
  pointer-events: none;
}

/* Only allow porter events on the navbar/menu. */
#space-view ~ .App .AppNavbar {
  pointer-events: auto;
}

/* Hide the primary component. */
#space-view ~ .App .App__primary__component,
/* Hide most Fullscreen components other than the AppPanel.
This prevents, for instance, the Step from overlaying Auth. */
#space-view
  ~ #fullscreen-portal
  .FullscreenContainer:not(:is(.FullscreenAppPanel, .FullscreenAlertBanner)),
  /* Hide the Step and Lesson theme bg which otherwise overlays Auth (i.e. /auth/sign-in/?next=.../step/...) */
  #space-view
  ~ #fullscreen-portal #fullscreen-theme-portal {
  display: none;
}

/* Ensure #space-view renders above .loading-splash (fixes background image layering) */
#space-view {
  z-index: 1;
  position: relative;
}

/* Make room for navbar. */
/* #space-view #auth-view > * {
  margin-top: 50px;
} */

/* ALERT: the following CSS causes the initial rendering of Quill editors to be drastically slower.  */

/* To workaround UIs that should always be in "light" mode */
/* https://github.com/chakra-ui/chakra-ui/issues/3753 */

/* Reset the color of all heading and p tags by explicitly using the
--chakra-colors-chakra-body-text var, which, in the context of a <LightMode />
wrapper, will be set to --chakra-colors-gray-800 */
/* html[data-theme="dark"] [data-theme="light"] :is(h1, h2, h3, h4, h5, h6, p) {
  color: var(--chakra-colors-chakra-body-text);
} */

/* Reset selection color and bg color for selected text wrapped by <LightMode />. */
/* html[data-theme="dark"] [data-theme="light"] ::selection {
  color: currentColor;
  background-color: highlight;
} */

/* Reset selection bg color for all text that is *not* explicitly wrapped by <DarkMode />.
This requires wrapping the beginning of a Chakra UI that is capable of dark mode with
the <DarkMode /> component when in dark mode. */
/* html[data-theme="dark"]
  :not([data-theme="dark"]):not(:has([data-theme="dark"]))
  ::selection {
  background-color: highlight;
} */

/* Hiding the PW3 mobile navigator menu button for now. */
#root .NavigatorMenuButton {
  display: none;
}

#webpack-dev-server-client-overlay {
  display: none;
}
