.SubscriptionInfo {
  position: relative;
  padding: 15px 25px;
}
.SubscriptionInfo img {
  height: 60px;
  float: left;
  margin-right: 10px;
}
.SubscriptionInfo h4 {
  margin-left: 70px;
}
.SubscriptionInfo p {
  color: #888;
}

.SubscriptionInfo__status {
  min-height: 50px;
}
.SubscriptionInfo__status p {
  margin-left: 70px;
}
